<template>
    <div class="">
        <div class="mx-auto max-w-2xl p-4 sm:px-6 sm:py-4 lg:max-w-7xl lg:px-8">
            <div class="w-full flex ">
                <div class="relative w-7/12 my-5 block">
                    <div class="h-11">
                        <input
                            class="appearance-none border-2 h-full pl-10 border-gray-300 hover:border-gray-400 transition-colors rounded-md w-full py-2 px-3 text-gray-800 leading-tight  focus:border-b-2 focus:border-primary focus:shadow-outline"
                            v-model="search" type="text" placeholder="Search..." />
                        <div class="absolute right-0 inset-y-0 flex items-center ">
                            <button class="bg-primary text-black text-sm font-Poppins font-normal rounded h-full px-3 py-2"
                                @click="clickSearch">Search</button>

                        </div>

                        <div class="absolute left-0 inset-y-0 flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-3 text-gray-400 hover:text-gray-500"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                    </div>

                </div>
                <div class="w-5/12 my-5 block">
                    <button class="float-right text-white b font-medium rounded-lg text-sm px-2 py-2 " type="button"
                        data-drawer-target="drawer-navigation" data-drawer-show="drawer-navigation"
                        aria-controls="drawer-navigation">
                        <img src="@/assets/images/filter-icon.png" class="w-7 h-7" />
                    </button>
                </div>
            </div>

            <div class="mt-6 grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-4">
                <div v-for="item in images" :key="item.id" class="group relative bg-white p-4 rounded-lg">
                    <div
                        class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md lg:aspect-none group-hover:opacity-75 ">
                        <img :src="item.path"
                            class=" w-full aspect-[7/5]  object-contain object-center lg:h-full lg:w-full" />
                    </div>
                    <div class="mt-4 flex justify-between">
                        <div>
                            <h3 class="text-black font-Poppins font-medium text-base">
                                <a :href="item.href">
                                    <span >
                                    <!-- {{ item.name }} -->
                                    {{ item.categories[0].title }}
                                    </span>
                                </a>
                            </h3>
                            <p class="mt-1 text-sm text-[#A7A9AC] font-Poppins font-normal">
                                <span v-for="(tag_item, index) in item.tags" :key="index" class="mr-1">
                                    #{{ tag_item.name }}</span>
                            </p>
                        </div>
                        <!-- <p class="text-sm font-medium text-gray-900">{{ item.price }}</p> -->
                    </div>
                    <div class="mt-4">
                        <button type="button"
                            class="bg-primary hover:bg-primary-600 text-white rounded-md px-4 py-2 w-full font-Poppins text-center font-semibold text-base"
                            @click="clickRequest(item)" >Request</button>
                    </div>
                </div>
            </div>

            <div class="mt-6 w-full">
                <vue-awesome-paginate class="paginate-container float-right" :total-items="media_total" :items-per-page="30"
                    :max-pages-shown="5" :show-ending-buttons="true" :show-jump-buttons="true" :firstPageContent="'<<'"
                    :lastPageContent="'>>'" v-model="currentPage" :on-click="onClickHandler" type="link"
                    link-url="/search?page=[page]">

                    <template #prev-button>
                        <font-awesome-icon icon="fa-solid fa-angle-left" />
                    </template>
                    <template #next-button>
                        <font-awesome-icon icon="fa-solid fa-angle-right" />
                    </template>
                    <template #first-page-button>
                        <font-awesome-icon icon="fa-solid fa-angles-left" />
                    </template>
                    <template #last-page-button>
                        <font-awesome-icon icon="fa-solid fa-angles-right" />
                    </template>

                </vue-awesome-paginate>
            </div>



            <!-- drawer component -->
            <div id="drawer-navigation"
                class=" fixed top-0 !right-full z-40 w-64 border-2 border-e-primary shadow h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white overflow-x-hidden"
                aria-labelledby="drawer-navigation-label">
                <h5 id="drawer-navigation-label"
                    class="text-base/8 font-semibold text-gray-500 uppercase flex border-b-2 border-[#e2e2e2] pb-4">
                    <img src="@/assets/images/document-filter.png" class="w-8 h-8 mr-2" />
                    <span>Filters</span>
                </h5>

                <button type="button" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation"
                    class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 end-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clip-rule="evenodd"></path>
                    </svg>
                    <span class="sr-only">Close menu</span>
                </button>
                <div class="py-4  h-4/5 w-56 ">


                    <Vueform ref="filtersForm" v-model="filter_data" class="w-full h-full mt-4 ">
                        <CheckboxgroupElement name="category" :items="category_list" label="Industry Categories"
                            :override-classes="{
                                ElementLabel: {
                                    container: 'font-Poppins font-normal text-base text-[#8e8e8e] pb-0'
                                }
                            }" />
                        <CheckboxgroupElement class="h-80 overflow-auto" name="tags" :items="tags_list"
                            label="Popular Keywords" :override-classes="{
                                ElementLabel: {
                                    container: 'font-Poppins font-normal text-base text-[#8e8e8e] pb-0'
                                }
                            }" />

                        <div class="fixed bottom-4 w-56 flex justify-between">
                            <ButtonElement name="clear_btn" button-label="Clear All" button-type="button" :resets="true"
                                @click="clickClear" class="btn-secondary-outline text-base/" secondary :override-classes="{
                                    ButtonElement: {
                                        button: 'bg-white text-[#979797] border-1 border-[#979797]'
                                    }
                                }" />
                            <ButtonElement name="filter_btn" button-label="Filter" button-type="button" 
                                @click="clickFilter" />
                        </div>
                    </Vueform>
                </div>


            </div>
        </div>

        <ImageDetails :selected_image="selected_image" />

    </div>
</template>


<script>
import { initFlowbite, Modal } from 'flowbite'
import axios from 'axios'
import ImageDetails from '@/components/ImageDetails.vue'


export default {
    name: 'SearchResultsFilters',
    components: {
        ImageDetails
    },

    data() {
        return {
            images: [
                // { name: "sign 1", path: "https://source.unsplash.com/bYuI23mnmDQ", tags: "#tag #tag2" },
                // { name: "sign 2", path: "https://source.unsplash.com/Nllx4R-2c3o", tags: "#tag #tag2" },
                // { name: "sign 3", path: "https://source.unsplash.com/lp40q07DIe0", tags: "#tag #tag2" },
                // { name: "sign 4", path: "https://source.unsplash.com/wfalq01jJuU", tags: "#tag #tag2" },
                // { name: "sign 5", path: "https://source.unsplash.com/rMHNK_skwwU", tags: "#tag #tag2" },
                // { name: "sign 6", path: "https://source.unsplash.com/WBMjuGpbrCQ", tags: "#tag #tag2" },
                // { name: "sign 7", path: "https://source.unsplash.com/nCUZ5BYBL_o", tags: "#tag #tag2" },
                // { name: "sign 8", path: "https://source.unsplash.com/3u4fzMQZhjc", tags: "#tag #tag2" },
                // { name: "sign 9", path: "https://source.unsplash.com/haOIqIPSwps", tags: "#tag #tag2" },
                // { name: "sign 10", path: "https://source.unsplash.com/3UrYD7NNVxk", tags: "#tag #tag2" },
                // { name: "sign 11", path: "https://source.unsplash.com/fm1JKDItlVM", tags: "#tag #tag2" },
                // { name: "sign 12", path: "https://source.unsplash.com/qPpq1EVs8vw", tags: "#tag #tag2" },
                // { name: "sign 13", path: "https://source.unsplash.com/xRyL63AwZFE", tags: "#tag #tag2" },
                // { name: "sign 14", path: "https://source.unsplash.com/XeNKWTiCPNw", tags: "#tag #tag2" },
                // { name: "sign 15", path: "https://source.unsplash.com/DFt3T5r_4FE", tags: "#tag #tag2" },
                // { name: "sign 16", path: "https://source.unsplash.com/Ebwp2-6BG8E", tags: "#tag #tag2" },
                // { name: "sign 17", path: "https://source.unsplash.com/wfalq01jJuU", tags: "#tag #tag2" },
                // { name: "sign 18", path: "https://source.unsplash.com/rMHNK_skwwU", tags: "#tag #tag2" },
                // { name: "sign 19", path: "https://source.unsplash.com/WBMjuGpbrCQ", tags: "#tag #tag2" },
                // { name: "sign 20", path: "https://source.unsplash.com/nCUZ5BYBL_o", tags: "#tag #tag2" },
                // { name: "sign 21", path: "https://source.unsplash.com/3u4fzMQZhjc", tags: "#tag #tag2" },
                // { name: "sign 22", path: "https://source.unsplash.com/3UrYD7NNVxk", tags: "#tag #tag2" },
                // { name: "sign 23", path: "https://source.unsplash.com/fm1JKDItlVM", tags: "#tag #tag2" },
                // { name: "sign 24", path: "https://source.unsplash.com/qPpq1EVs8vw", tags: "#tag #tag2" },
                // { name: "sign 25", path: "https://source.unsplash.com/xRyL63AwZFE", tags: "#tag #tag2" },
                // { name: "sign 26", path: "https://source.unsplash.com/XeNKWTiCPNw", tags: "#tag #tag2" },
                // { name: "sign 27", path: "https://source.unsplash.com/DFt3T5r_4FE", tags: "#tag #tag2" },
                // { name: "sign 28", path: "https://source.unsplash.com/Ebwp2-6BG8E", tags: "#tag #tag2" },
            ],

            currentPage: 1,
            media_total: 0,

            search: null,

            filter_data:null,
            category_list: [],
            tags_list: [],

            selected_image: null
        }
    },
    mounted() {
        console.log(this.$route)
        console.log(this.$router)
        initFlowbite()

        console.log(this.$route.query.q)
        this.search = this.$route.query.q


        this.getCategoryList()
        this.getTagsList()
        this.getImagesRequest()


        console.log(this.$refs.filtersForm)
        console.log(document.getElementById('static-modal')) 
        console.log(this.$refs.imageDetailsModalRef)

    },
    computed: {
    },
    methods: {

        clickSearch() {
            this.$router.push('/search?q=' + this.search)
            this.getImagesRequest()
        },
        getCategoryList() {
            axios
                .get("/category/list", {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'SAFELINE',
                        // Authorization:
                        //     "Bearer " +
                        //     JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);
                    this.category_list = response.data.data

                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-primary !focus:shadow-none",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });



        },
        getTagsList() {
            axios
                .get("/tags/list", {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'SAFELINE',
                        // Authorization:
                        //     "Bearer " +
                        //     JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);
                    this.tags_list = response.data.data

                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-primary !focus:shadow-none",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });


        },
        onClickHandler(page) {
            console.log(page)
        },
        getImagesRequest() {
            let params_request = ''

            params_request += "?page="+this.currentPage

            if (this.search != null && this.search != "") {
                params_request += "&keyword=" + this.search
            }

            console.log(this.filter_data)
            if(this.filter_data && this.filter_data.category.length>0){
                params_request += "&categories_ids="+this.filter_data.category.toString()
            }
            if(this.filter_data && this.filter_data.tags.length>0){
                params_request += "&tag_ids="+this.filter_data.tags.toString()
            }


            console.log(params_request)

            axios
                .get("/media/list" + params_request, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        client: 'SAFELINE',
                        // Authorization:
                        //     "Bearer " +
                        //     JSON.parse(window.localStorage.getItem("user")).access_token,
                    },
                })
                .then(async (response) => {
                    console.log(response);

                    this.images = response.data.data
                    this.media_total = response.data.meta.total
                })
                .catch((error) => {
                    console.log(error)
                    this.is_loading = false;
                    // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                    // if (error_message == null || error_message == '') {
                    //     error_message = 'Sorry, '
                    // }
                    let error_message = ''
                    if (error.response) {
                        console.error("error.response.data", error.response.data);
                        console.error("error.response.status", error.response.status);
                        console.error("error.response.headers", error.response.headers);
                        error_message = error.response.data.error
                    } else if (error.request) {
                        console.error("error-request", error.request);
                        console.error("Error-message", error.message);
                        error_message = error.message
                    } else {
                        console.error("Error-message", error.message);
                        error_message = error.message
                    }
                    this.$swal({
                        // title: "Title",
                        text: error_message,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-primary !focus:shadow-none",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });


        },
        clickFilter() { 
            console.log("click filter")
            console.log(this.category_list)
            console.log(this.tags_list)

            this.getImagesRequest()
        },
        clickClear() { },


        clickRequest(img_item) {
            console.log(img_item)
            console.log(this.$refs.imageDetailsModalRef)
            console.log(document.getElementById('static-modal'))
            // this.$refs.imageDetailsModalRef.show();
            this.selected_image = img_item

            let modal_item = document.getElementById('static-modal')

            const modal = new Modal(modal_item);
            modal.show()


        }, 
    }
}
</script>

<style ></style>