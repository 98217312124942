<template>
    <MainSection />
    <GallerySection />
    <JoinEvolution />
    <ContactUsForm />
</template>


<script>
import MainSection from '@/components/landing_page/MainSection.vue'
import GallerySection from '@/components/landing_page/GallerySection.vue'
import JoinEvolution from '@/components/landing_page/JoinEvolution.vue'
import ContactUsForm from '@/components/landing_page/ContactUs.vue'

export default {
    name: 'LandingPage',
    components: {
        MainSection, GallerySection, JoinEvolution, ContactUsForm
    },

    data() {
        return {
            user: null
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
    }
}
</script>
