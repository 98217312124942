
<template>
    <section>
        <div class="mt-5 p-5 sm:p-8">
            <div class="w-full flex">
                <div class="2xl:w-5/12 lg:w-7/12 md:w-full w-full px-3">
                    <h1 class="section-title">
                        Contact Us
                    </h1>
                    <Vueform :float-placeholders="false" v-model="formData" validate-on="change|step" class=" mx-auto"
                        ref="contactUsFormRef" :endpoint="false" method="POST">

                        <GroupElement name="container">
                            <TextElement name="first_name" label="First name" placeholder="First name" :columns="{
                                container: 6,
                                label: 12,
                                wrapper: 12,
                            }" field-name="First name" :rules="['required', 'max:255']" />

                            <TextElement name="last_name" label="Last name" placeholder="Last name" :columns="{
                                container: 6,
                                label: 12,
                                wrapper: 12,
                            }" field-name="Last name" :rules="['required', 'max:255']" />
                        </GroupElement>

                        <TextElement name="sender_email" label="Email" input-type="email" placeholder="Email"
                            field-name="Email" :rules="['required']" />

                        <TextareaElement name="body" label="Inquiry" placeholder="Inquiry" field-name="Inquiry"
                            :rules="['required']" :rows="5" />


                        <ButtonElement name="contact_btn" button-label="Send" :submits="true" align="right"
                            @click="clickSend" :loading="is_loading"/>



                    </Vueform>
                </div>
                <div class="2xl:w-7/12 lg:w-5/12 hidden px-3 lg:flex">
                    <img src="@/assets/images/logo.png" class="w-2/3 align-middle m-auto aspect-auto" />

                </div>
            </div>
        </div>

    </section>
</template>

<script>
import axios from 'axios'
export default {
    name: 'ContactUsForm',
    components: {
    },

    data() {
        return {
            formData: null,
            is_loading: false

        }
    },
    mounted() {
        console.log(this.$refs.contactUsFormRef)
    },
    computed: {
    },
    methods: {
        clickSend(form, el, event) {
            console.log("in click send contact us")
            console.log(form)
            console.log(el)
            console.log(event)

            event.preventDefault()
            let request_form_ref = this.$refs.contactUsFormRef
            request_form_ref.validate()
            let form_errors = request_form_ref.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true

                console.log(this.formData)



                // let temp = this
                // setTimeout(function () {
                //     temp.is_loading = false
                // }, 5000);

                let requestData = {
                    client: "SAFELINE",
                    sender_name: this.formData.first_name + ' ' + this.formData.last_name,
                    sender_email: this.formData.sender_email,
                    body: this.formData.body

                }
                console.log(requestData)
                console.log(this.requestFormData)


                axios
                    .post("common/send-contact-us-form", requestData, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            client: 'SAFELINE',
                            // Authorization:
                            //     "Bearer " +
                            //     JSON.parse(window.localStorage.getItem("user")).access_token,
                        }
                    },)
                    .then((response) => {
                        console.log(response);
                        console.log(response.data);

                        console.log("success")
                        this.$swal({
                            // title: "Title",
                            text: response.data.success,
                            icon: "success",
                            // timer: 3000,
                            customClass: {
                                htmlContainer: "!text-2xl",
                                confirmButton: "!bg-primary !focus:shadow-none",
                            },
                        }).then((r) => {
                            // console.log(r.value);
                            this.is_loading = false;
                            this.clickCloseModal()
                        });

                    })
                    .catch((error) => {
                        console.log(error.response.data.error);
                        console.log(error.request);
                        console.log(error.message);
                        // this.loginErrorMessage = error.response.data.error;
                        // this.showLoginErrorMessage = true;

                        this.$swal({
                            // title: "Title",
                            text: error.response.data.error,
                            icon: "error",
                            // timer: 3000,
                            customClass: {
                                htmlContainer: "!text-2xl",
                                confirmButton: "!bg-primary !focus:shadow-none",
                            },
                        }).then((r) => {
                            // console.log(r.value);
                            this.is_loading = false;
                        });
                    });

            }
        }
    }
}
</script>
