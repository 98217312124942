<template>
  <div class="h-full">
    <NavbarDefault :is_user="is_login"/>
    <div class="mt-20"><router-view /></div>
    <FooterDefault class="mt-10" />
  </div>
</template>

<script>
import NavbarDefault from './components/layout/NavbarDefault.vue';
import FooterDefault from './components/layout/FooterDefault.vue'

export default {
  name: 'App',
  components: {
    NavbarDefault, FooterDefault
  },

  data() {
    return {
      is_login: null
    }
  },
  updated() {
    console.log("uuu")
    let user = JSON.parse(localStorage.getItem('user'))
    this.is_login = user ? true : false

  }
}
</script>

<style></style>
