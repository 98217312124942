<template>
    <section class="main-section-container ">
        <div class="mx-auto w-full md:px-16 px-4  py-24 lg:py-56">
            <h1 class="main-section-title">
                Driving the Evolution of Safety
            </h1>
            <h1 class="main-section-title mt-2">
                 One Site at a time...
            </h1>

        </div>
    </section>
</template>


<script>
export default {
    name: 'MainSection',
    components: {
    },

    data() {
        return {
            user: null
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
    }
}
</script>
