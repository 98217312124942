import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import VueAxios from 'vue-axios'
import axios from 'axios'


import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faBars, faCircle, faCircleArrowRight, faCircleInfo, faMicrophone, faPause } from '@fortawesome/free-solid-svg-icons'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF,faLinkedin, faSquareInstagram, faSquareXTwitter, faYoutube  } from '@fortawesome/free-brands-svg-icons'
library.add({faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faBars, faCircle, faCircleArrowRight, faCircleInfo, faCopyright, faMicrophone, faPause });
library.add({faFacebookF,faLinkedin, faSquareInstagram, faSquareXTwitter, faYoutube});


import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import './assets/css/styles.css'

import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";

import Vueform from '@vueform/vueform'
import vueformConfig from '../vueform.config'

const app = createApp(App)

app.component("font-awesome-icon", FontAwesomeIcon)

axios.defaults.withCredentials = false
axios.defaults.baseURL = 'https://microservices-staging.bumblebeeai.io/api/'
// axios.defaults.baseURL = 'https://microservices.bumblebeeai.io/api/'
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
app.use(VueAxios, axios)

app.use(VueTelInput, {
    mode: 'international',
    enabledCountryCode: true,
    defaultCountry: 'IE',
    preferredCountries: ['IE', 'GB', 'FR'],
    autoFormat: true,
    dropdownOptions: { showSearchBox: true },
    inputOptions: { placeholder: "Phone number", showDialCode: true }

});

app.use(VueSweetalert2)

app.use(router)
app.use(Vueform, vueformConfig)

app.use(VueAwesomePaginate)

app.mount('#app')
