// import Vue from 'vue'
import { createRouter, createWebHistory } from "vue-router";

import LandingPage from '@/views/LandingPage.vue'
import SearchResultsFilters from '@/views/SearchResultsFilters.vue'

import LoginView from '@/views/auth/LoginView.vue'
import RegisterView from '@/views/auth/RegisterView.vue'

const routes = [

  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },
  {
    path: '/search',
    name: 'SearchResultsFilters',
    component: SearchResultsFilters,
    beforeEnter: (to, from, next) => redirectIfNotAuthed(to, from, next)
  },
  
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: RegisterView,
    beforeEnter: (to, from, next) => checkIfAuthed(to, from, next)
  },


]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
  },
});


function checkIfAuthed(to, from, next) {
  // console.log(localStorage)
  console.log(to)
  console.log(from)
  console.log(next)
  let user = JSON.parse(localStorage.getItem('user'))
  let user_type = localStorage.getItem("user_type")
  console.log(user)
  // console.log(user_type)
  // console.log(user_type == 'user', to.path.includes('c-risk'))
  // console.log("========================")
  // console.log(user != null && user.client=='SAFELINE')
  // console.log(user )
  // console.log(user.client=='SAFELINE')
  if (user != null && user.client=='SAFELINE') {
    next({ name: 'SearchResultsFilters' })
  } else {
    next();

  }
}

function redirectIfNotAuthed(to, from, next) {
  console.log(localStorage)
  // let user = localStorage.getItem('user');
  // let type = localStorage.getItem('user_type');
  // console.log(user)
  // console.log(type, user_type)
  // console.log(to.path.includes('c-risk'))
  // console.log("----------------------")
  // console.log(to)
  // console.log(from)
  // console.log(next)
  // if (user === null) {
  //   next({ name: 'LoginView' })

  // } else {
    next();
  // }
}


export default router
