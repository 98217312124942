// vueform.config.(js|ts)

// import en from '@vueform/vueform/locales/en'
// import tailwind from '@vueform/vueform/themes/tailwind'
// import { defineConfig } from '@vueform/vueform'
// import builder from '@vueform/builder/plugin'

// export default defineConfig({
//   theme: tailwind,
//   locales: { en },
//   locale: 'en',
//   apiKey: 'z3z3-azyu-v1bb-wjrl-ksbg',
//   plugins: [
//     builder,
//   ],
// })

// // vueform.config.js

import en from '@vueform/vueform/locales/en'
import tailwind from '@vueform/vueform/themes/tailwind'
import { defineConfig } from '@vueform/vueform'

// import PhoneElement from '@/components/VueForm/PhoneElement.vue'
// import QuillEditorElement from '@/components/VueForm/QuillEditorElement.vue'
// import FileInputElement from '@/components/VueForm/FileInputElement.vue'

export default defineConfig({
  theme: tailwind,
  locales: { en },
  locale: 'en',
  // replaceClasses: {
  //   ElementLabel: {
  //     container: {
  //       'form-pb-gutter/3': 'pb-0',
  //       'form-pt-0': 'mt-2'
  //     },
  //   },
  // },
  removeClasses: {
      TextElement: {
        input: ['bg-transparent', "with-floating:form-p-input-floating"      ],
        inputContainer: ["focused:form-bg-input-focus", "focused:form-color-input-focus", "focused:form-border-color-input-focus",
          "focused:form-shadow-input-focus", "focused:form-ring",
          "focused-hover:form-shadow-input-hover"],
        inputContainer_focused: ["form-bg-input-focus", "form-color-input-focus", "form-border-color-input-focus",
          "form-shadow-input-focus", "form-ring",
          "form-shadow-input-hover"],
        input_focused: ["form-color-input-focus", "form-autofill-focus"]

      
    },
    ElementLabel: {
      container: ['form-pb-gutter/3']
    }
  },
  addClasses: {
    // TextElement: {
    //   input: ['bg-gray-50', 'rounded', 'border', 'border-gray-100'],
    //   // input_focused: ["border-red-400"]
    // },
    // ElementLabel: {
    //   container: ['dfghjk'],
    //   labelContainer:['testlabl']
    // }
  },
  overrideClasses: {
    TextElement: {
      inputContainer: 'border border-gray-100 bg-gray-50 rounded w-full ',
      inputContainer_default: 'border-grey-50',
      inputContainer_focused: 'border-[3px] border-primary rounded-b-none outline-2 ',
      input_focused: 'rounded-b-none'
    },
    ElementLabel: {
      container: 'font-Poppins font-normal text-base text-[#FFC311] pb-0'
    },
    ButtonElement:{
      button:'bg-primary border-2 font-Poppins font-semibold text-xl text-center text-black',
      button_primary:'bg-primary border-2 border-primary font-Poppins font-semibold text-base/6 text-center text-white ',
      button_secondary:'bg-white border-2 border-[#979797] font-Poppins font-semibold text-base/6 text-center text-[#979797] '
    }

  },

  locationProvider: 'google',
  elements: [
    // PhoneElement, QuillEditorElement, FileInputElement
  ],

});
