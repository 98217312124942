<template>
    <div class="auth-container auth-container h-screen  py-5 ">
        <div class="row h-full m-auto">
            <div class="xl:w-4/12 lg:w-5/12 md:w-6/12 w-[98%] mx-auto my-auto">

                <div class="card card-auth mx-auto  px-4 py-6 bg-[#141011] rounded-xl shadow-[0px 0px 16px 0px #525F7F3D]">
                    <img src="@/assets/images/logo.png" class="logo-img mb-6 mt-4 w-36 h-12 mx-auto" />
                    <Vueform :float-placeholders="false" v-model="formData" validate-on="change|step"
                        class="w-10/12 mx-auto" ref="loginFormRef" :add-class="'fotm-auth'"
                        :remove-class="{ div: ['grid'] }">
                        <StaticElement name="welcomeH3" tag="h3" :content="'Welcome'" class=""
                            :attrs="{ class: ['sub-title-h3', 'mb-1', 'font-Poppins', 'font-semibold', 'text-xl','text-[#FFC311]'] }" />
                        <StaticElement name="welcomeText" tag="p"
                            :content="'Please sign-in to your account to view our services'"
                            :attrs="{ class: ['text-p', 'mb-4',  'font-Poppins', 'font-normal', 'text-md', 'text-[#b5b5c3]'] }" />

                        <TextElement name="email" input-type="email" label="Email" placeholder="Enter email"
                            :field-name="'Email'" rules="required" class="mb-3" />


                        <TextElement name="password" input-type="password" label="Password" placeholder="Enter password"
                            :field-name="'Password'" rules="required" class="mb-3">
                            <!-- <template #label>
                    <div class="flex justify-between w-fit">
                        <span>Password</span>

                        <router-link :to="base_url+'/forget_password'" class="form-link">Forget password</router-link>
                    </div>
                </template> -->
                        </TextElement>

                        <!-- <StaticElement :href="'/forget_password'" tag="a" content="Forget password?"
                            :add-class="{ 'tag_a': ['form-link', 'float-right'] }" /> -->


                    </Vueform>

                    <div class="w-10/12 mx-auto mb-6 mt-10">
                        <button class="bg-primary hover:bg-primary-600 text-white rounded-md px-4 py-2 w-full font-Poppins text-center font-semibold text-base"
                            @click="clickLogin">
                            Login</button>

                    </div>


                  

                    <div  class="w-10/12 mx-auto mt-8 text-center  auth-text-link font-Poppins text-normal text-base">
                        <span class="text-[#b5b5c3]">New on our platform?
                        </span>
                        <span class="text-primary">
                            <router-link to="/register">
                                Create an account
                            </router-link>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "LoginView",
    components: {  },
    data() {
        let temp = this;
        return {
            client_name: 'SAFELINE',

            formData: null,

            is_loading: false

        }
    },
    mounted() {

    },
    updated() {
    },
    watch: {
    },
    methods: {
        clickLogin() {
            // this.$router.push("/situation_analysis")


            let login_form = this.$refs.loginFormRef
            login_form.validate()
            let form_errors = login_form.formErrors
            if (form_errors.length > 0) {
                console.log("not valid")

            } else {
                console.log('validated')
                this.is_loading = true
                this.sendLoginForm()
            }


        },

        sendLoginForm() {
            console.log("in login request")
            axios
                .post("user/login", {
                    email: this.formData.email,
                    password: this.formData.password,
                    client: this.client_name,
                })
                .then((response) => {
                    console.log(response);
                    console.log(response.data);

                    console.log("success")
                    // window.localStorage.setItem("email", this.formData.email);
                    window.localStorage.setItem("user", JSON.stringify(response.data));
                    window.localStorage.setItem("user_type", "user");
                    this.$router.go('/search');
                    // window.localStorage.setItem("user", JSON.stringify(response.data));
                    // window.localStorage.setItem("user_type", "user");
                    // // this.$router.push({ name: "SituationAnalysisIndex" });

                    // this.$router.push({ path: '/situation_analysis' })
                    // .then(() => { this.$router.go(0) })


                })
                .catch((error) => {
                    console.log(error.response.data.error);
                    console.log(error.request);
                    console.log(error.message);
                    // this.loginErrorMessage = error.response.data.error;
                    // this.showLoginErrorMessage = true;

                    this.$swal({
                        // title: "Title",
                        text: error.response.data.error,
                        icon: "error",
                        // timer: 3000,
                        customClass: {
                            htmlContainer: "!text-2xl",
                            confirmButton: "!bg-primary !focus:shadow-none",
                        },
                    }).then((r) => {
                        // console.log(r.value);
                        this.is_loading = false;
                    });
                });
        }

    }
}
</script>

<style>
.fotm-auth>div {
    display: block;
}
</style>