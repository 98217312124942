<template>
    <section>
        <div class="p-5 sm:p-8">

            <div class="relative w-10/12 my-5 mx-auto">
                <div>
                    <input
                        class="appearance-none border-2 pl-10 border-gray-300 hover:border-gray-400 transition-colors rounded-md w-full py-2 px-3 text-gray-800 leading-tight focus:outline-none focus:ring-purple-600 focus:border-purple-600 focus:shadow-outline"
                        v-model="search_gallery" type="text" placeholder="Search..." />
                    <div class="absolute right-0 inset-y-0 flex items-center">
                        <button class="bg-primary text-black text-sm font-Poppins font-normal rounded h-full px-3 py-2"
                            @click="clickSearch">Search</button>

                    </div>

                    <div class="absolute left-0 inset-y-0 flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 ml-3 text-gray-400 hover:text-gray-500"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                </div>
            </div>

            <div
                class="columns-2 gap-3 sm:columns-3 sm:gap-3 md:columns-4 lg:columns-6 [&>div:not(:first-child)]:mt-3 overflow-y-auto">
                <div v-for="(item, index) in images" :key="index" class="rounded">
                    <img :src="item.path" class="rounded-lg cursor-pointer" @click="clickImg(item)" data-modal-target="static-modal"
                        data-modal-toggle="static-modal" />
                </div>

            </div>

            <div class="float-right text-primary" v-if="media_total > 30">
                <router-link to="/search">View all</router-link>
            </div>

            <ImageDetails :selected_image="selected_image" />


        </div>
    </section>
</template>

<script>
import axios from 'axios';
import { initFlowbite, Modal } from 'flowbite';
import ImageDetails from '../ImageDetails.vue'

export default {
    name: 'GallerySection',
    components: {
        ImageDetails
    },

    data() {
        return {
            images: [
                // { name: "sign 1", path: "https://source.unsplash.com/bYuI23mnmDQ" },
                // { name: "sign 2", path: "https://source.unsplash.com/Nllx4R-2c3o" },
                // { name: "sign 3", path: "https://source.unsplash.com/lp40q07DIe0" },
                // { name: "sign 4", path: "https://source.unsplash.com/wfalq01jJuU" },
                // { name: "sign 5", path: "https://source.unsplash.com/rMHNK_skwwU" },
                // { name: "sign 6", path: "https://source.unsplash.com/WBMjuGpbrCQ" },
                // { name: "sign 7", path: "https://source.unsplash.com/nCUZ5BYBL_o" },
                // { name: "sign 8", path: "https://source.unsplash.com/3u4fzMQZhjc" },
                // { name: "sign 9", path: "https://source.unsplash.com/haOIqIPSwps" },
                // { name: "sign 10", path: "https://source.unsplash.com/3UrYD7NNVxk" },
                // { name: "sign 11", path: "https://source.unsplash.com/fm1JKDItlVM" },
                // { name: "sign 12", path: "https://source.unsplash.com/qPpq1EVs8vw" },
                // { name: "sign 13", path: "https://source.unsplash.com/xRyL63AwZFE" },
                // { name: "sign 14", path: "https://source.unsplash.com/XeNKWTiCPNw" },
                // { name: "sign 15", path: "https://source.unsplash.com/DFt3T5r_4FE" },
                // { name: "sign 16", path: "https://source.unsplash.com/Ebwp2-6BG8E" },
                // { name: "sign 17", path: "https://source.unsplash.com/wfalq01jJuU" },
                // { name: "sign 18", path: "https://source.unsplash.com/rMHNK_skwwU" },
                // { name: "sign 19", path: "https://source.unsplash.com/WBMjuGpbrCQ" },
                // { name: "sign 20", path: "https://source.unsplash.com/nCUZ5BYBL_o" },
                // { name: "sign 21", path: "https://source.unsplash.com/3u4fzMQZhjc" },
                // { name: "sign 22", path: "https://source.unsplash.com/3UrYD7NNVxk" },
                // { name: "sign 23", path: "https://source.unsplash.com/fm1JKDItlVM" },
                // { name: "sign 24", path: "https://source.unsplash.com/qPpq1EVs8vw" },
                // { name: "sign 25", path: "https://source.unsplash.com/xRyL63AwZFE" },
                // { name: "sign 26", path: "https://source.unsplash.com/XeNKWTiCPNw" },
                // { name: "sign 27", path: "https://source.unsplash.com/DFt3T5r_4FE" },
                // { name: "sign 28", path: "https://source.unsplash.com/Ebwp2-6BG8E" },
            ],
            media_total: 0,

            search_gallery: '',

            selected_image: null
        }
    },
    watch: {
        search_gallery(newval, oldval) {
            // console.log("Watch", newval, new Date().getMilliseconds())

            // setTimeout(() => {
            //     console.log("Delayed for 1 second.");
            // }, 5000);
        }
    },
    mounted() {
        axios
            .get("media/list", {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    client: 'SAFELINE',
                    // Authorization:
                    //     "Bearer " +
                    //     JSON.parse(window.localStorage.getItem("user")).access_token,
                },
            })
            .then(async (response) => {
                console.log(response);

                this.images = response.data.data
                this.media_total = response.data.meta.total

                initFlowbite()
            })
            .catch((error) => {
                console.log(error)
                this.is_loading = false;
                // let error_message = error.response.data.error ? error.response.data.error : error.response.data.message
                // if (error_message == null || error_message == '') {
                //     error_message = 'Sorry, '
                // }
                let error_message = ''
                if (error.response) {
                    console.error("error.response.data", error.response.data);
                    console.error("error.response.status", error.response.status);
                    console.error("error.response.headers", error.response.headers);
                    error_message = error.response.data.error
                } else if (error.request) {
                    console.error("error-request", error.request);
                    console.error("Error-message", error.message);
                    error_message = error.message
                } else {
                    console.error("Error-message", error.message);
                    error_message = error.message
                }
                this.$swal({
                    // title: "Title",
                    text: error_message,
                    icon: "error",
                    // timer: 3000,
                    customClass: {
                        htmlContainer: "!text-2xl",
                        confirmButton: "!bg-grey-protostars",
                    },
                }).then((r) => {
                    // console.log(r.value);
                    this.is_loading = false;
                });
            });


    },
    computed: {
        filteredList() {
            console.log("computed", new Date().getMilliseconds())
            return this.images.filter(item => item.name.includes(this.search_gallery));
        }
    },
    methods: {
        clickSearch() {
            this.$router.push('/search?q=' + this.search_gallery)
        },
        clickImg(img_item) {
            console.log(img_item)
            console.log(this.$refs.imageDetailsModalRef)
            console.log(document.getElementById('static-modal'))
            // this.$refs.imageDetailsModalRef.show();
            this.selected_image = img_item

            let modal_item = document.getElementById('static-modal')

            const modal = new Modal(modal_item);
            modal.show()


        }
    }
}
</script>
