<template>
    <footer class="bg-black w-full mt-16">
        <div class="w-full  mx-auto p-4 md:py-8 px-16">
            <div class="sm:flex sm:items-center sm:justify-between justify-center">
                <a href="/" class="sm:flex block items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
                    <img src="@/assets/images/logo.png" class="w-36 h-11 sm:mx-0 mx-auto" alt="Logo" />
                </a>
                <div class="flex">
                    <ul
                        class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400 sm:mx-0 mx-auto">
                        <li class="nav-footer-item">
                            <a href="#" class=""><font-awesome-icon icon="fa-brands fa-square-instagram" /></a>
                        </li>
                        <li class="nav-footer-item">
                            <a href="#" class=""><font-awesome-icon icon="fa-brands fa-facebook-f" /></a>
                        </li>
                        <li class="nav-footer-item">
                            <a href="#" class=""><font-awesome-icon icon="fa-brands fa-square-x-twitter" /></a>
                        </li>
                        <li class="nav-footer-item">
                            <a href="#" class=""><font-awesome-icon icon="fa-brands fa-youtube fa-lg" /></a>
                        </li>
                        <li class="nav-footer-item">
                            <a href="#" class=""><font-awesome-icon icon="fa-brands fa-linkedin" /></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="sm:flex sm:items-center sm:justify-between justify-center sm:mt-10 mt-6">
                <div class="flex">
                    <ul
                        class="flex  items-center sm:text-left text-center mb-6 text-sm font-medium  text-gray-500 sm:mb-0 dark:text-gray-400  sm:mx-0 mx-auto">
                        <li class="nav-footer-item">
                            <a href="#" class="">Privacy Policy</a>
                        </li>
                        <li class="nav-footer-item">
                            <a href="#" class="">Terms & Conditions</a>
                        </li>
                    </ul>
                </div>
                <span class="nav-footer-text sm:text-right text-center"><font-awesome-icon icon="fa-regular fa-copyright" />2023
                    Protostars.ai - All
                    rights reserved</span>

            </div>
        </div>
    </footer>
</template>

<script>

export default {
    name: 'FooterDefault',
    components: {
    },

    data() {
        return {
        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
    }
}
</script>

<style></style>
