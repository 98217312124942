
<template>
    <section>
        <div class="mt-5 p-5 sm:p-8 ">
            <div class="w-full flex">
                <div class="2xl:w-5/12 lg:w-7/12 md:w-full w-full px-3">
                    <h1 class="section-title">
                        Join the evolution!
                    </h1>
                    <p class="section-text ">We're driving the safety agenda on sites worldwide through the development and delivery of more
                        considered safety communications.Our global clients benefit from our unique database of Safeline
                        assets that includes; 1,000's of illustrations, orientation presentations, inductions, tool box
                        talks, safety manuals, way finding systems, communications campaigns, health & safety signage
                        solutions and more.

                    </p>
                </div>
                <div class="2xl:w-7/12 lg:w-5/12 hidden px-3 lg:flex">
                    <img src="@/assets/images/evaluation.png" class="w-full align-middle m-auto aspect-auto"/>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'JoinEvolution',
    components: {
    },

    data() {
        return {

        }
    },
    mounted() {
    },
    computed: {
    },
    methods: {
    }
}
</script>
