<template>
    <nav class="bg-black h-20  fixed w-full z-20 top-0 start-0 border-0 ">
        <div class="max-w-screen-xl min-w-full flex flex-wrap items-center justify-between mx-auto p-4 md:px-16 px-4  ">
            <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse">
                <img src="@/assets/images/logo.png" class="w-36 h-11" alt="Protostars Logo" />
                <!-- <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span> -->
            </a>
            <div  class="flex items-center md:order-1 space-x-3 md:space-x-0 rtl:space-x-reverse">

                <!-- <ul v-else class="mr-3">

                    <li> <img src="@/assets/images/cart-icon.png" class="w-8"/> </li>
                </ul> -->

                <button v-if="user" type="button" class="flex text-sm bg-transparent lg:ml-2  md:ml-0" id="user-menu-button"
                    aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                    <span class="sr-only">Open user menu</span>
                    <div class="navbar-avatar">
                        <span class="font-semibold text-lg bg-transparent uppercase">{{ firstCharUser }}</span>
                    </div>
                    <div class="navbar-username">
                        {{ user.first_name }} {{ user.last_name }}
                    </div>
                </button>
                <!-- Dropdown menu -->
                <div v-if="user"
                    class="z-50 hidden my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600"
                    id="user-dropdown">
                    <div class="px-4 py-3">
                        <span class="navbar-dropdown-name">{{ user.first_name }} {{ user.last_name }}</span>
                        <span class="navbar-dropdown-email">{{ user.email }}</span>
                    </div>
                    <ul class="py-2" aria-labelledby="user-menu-button">
                        <li>
                            <a @click="clickLogout"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Logout</a>
                        </li>
                    </ul>
                </div>
                <button v-if="user == null"  data-collapse-toggle="navbar-user" type="button"
                    class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                    aria-controls="navbar-user" aria-expanded="false">
                    <span class="sr-only">Open main menu</span>
                    <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 17 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M1 1h15M1 7h15M1 13h15" />
                    </svg>
                </button>
            </div>
            <!-- <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-user">
                <ul
                    class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-transparent md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                    
                   
                    <li>
                        <a href="#"
                            class="block py-2 px-3 text-gray-900 rounded md:bg-transparent  md:hover:text-yellow-protostars md:p-0 md:dark:text-blue-500"
                            aria-current="page">Wishlist</a>
                    </li>
                    <li>
                        <a href="#"
                            class="block py-2 px-3 text-gray-900 rounded  md:hover:bg-transparent md:hover:text-yellow-protostars md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Profile</a>
                    </li>
                    <li>
                        <a href="#"
                            class="block py-2 px-3 text-gray-900 rounded  md:hover:bg-transparent md:hover:text-yellow-protostars md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">Logout</a>
                    </li>
                </ul>
            </div> -->
            <div v-if="user == null" class="items-center justify-between hidden w-full md:flex md:w-auto md:order-2"
                id="navbar-user">

                <ul
                    class="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg sm:bg-transparent bg-black-200 md:space-x-4 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent ">


                    <li class="nav-item-li">
                        <a href="/login" class=" " aria-current="page">Login</a>
                    </li>
                    <li class="nav-item-li">
                        <a href="/register" class=" ">Signup</a>
                    </li>


                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
// import { initFlowbite } from 'flowbite'


export default {
    name: 'NavbarDefault',
    components: {
    },
    props: ["is_user"],

    data() {
        return {
            user: null
        }
    },
    mounted() {
        // initFlowbite()

        this.user = JSON.parse(localStorage.getItem('user'))
        console.log(this.user)
    },
    updated() {
        console.log("update")
    },
    computed: {
        firstCharUser() {
            return this.user ? this.user.first_name.charAt(0) + this.user.last_name.charAt(0) : ''
        }
    },
    methods: {
        clickLogout() {
            localStorage.clear()
            this.$router.go('/login')
        }
    }
}
</script>

<style></style>
